<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items.items"
      item-key="id"
      class="elevation-0 background-transparent"
      :items-per-page="25"
      :loading="loading"
      :options.sync="options"
      :server-items-length="items.meta.totalItems"
      :footer-props="{
        'items-per-page-options': rowsPerPageItemsGlobal
      }"
    >
      <template v-slot:top>
        <div class="align-center pr-3 mb-5" style="width: 100%">
          <div class="d-inline-flex align-center justify-space-between max-width">
            <div class="d-flex align-center">
              <v-switch
                v-model="activeOnly"
                data-cy="switch-active-only"
                :label="$lang.labels.activeOnly"
                class="pl-3 pt-1 pr-3"
                inset
                dense
              ></v-switch>
              <v-text-field
                v-model="search"
                class="custom-search-perm"
                outlined
                hide-details
                clearable
                dense
                :label="$lang.labels.searchByName"
                data-cy="search-by-name"
                style="max-width: 340px"
              ></v-text-field>
              <v-select
                v-model="selectedType"
                class="ml-2 custom-search-perm"
                hide-details
                :items="typesFormatted"
                :label="$lang.labels.type"
                outlined
                dense
                clearable
              ></v-select>
            </div>
            <div class="d-flex">
              <div>
                <v-btn
                  text
                  light
                  class="ml-2"
                  color="primary"
                  @click="$emit('fetchCredentials', { options: options, search: search && search.length > 1 ? search : '', type: selectedType, resetPage: true })"
                >
                  <v-icon
                    dense
                    small
                  >
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </div>
              <div class="custom-button-wrapper">
                <v-btn
                  v-if="roles.includes('CREDENTIAL_CREATOR')"
                  outlined
                  color="accent"
                  class="ml-2 color-accent-text"
                  @click="showTypeSelector = true"
                >
                  <v-icon
                    left
                    dark
                  >
                    mdi-file-plus
                  </v-icon>
                  {{ $lang.actions.create }}
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:item.id="{ item }">
        <div class="font-weight-bold"># {{ item.id }}</div>
      </template>

      <template v-slot:item.name="{ item }">
        <div class="d-flex justify-space-between align-center">
          <!-- Name on the left with a fixed or max width -->
          <div class="text-start name-container clickable-simple"> <!-- Adjust the margin-right here -->
            <router-link v-if="item.name.length < 86" class="text-decoration-none" :to="{ name: 'credentialEdit', params: { id: item.id } }">
              <span class="font-weight-regular color-primary">{{ item.name }}</span>
            </router-link>
            <v-tooltip v-else top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <router-link
                    class="text-decoration-none"
                    :to="{ name: 'credentialEdit', params: { id: item.id } }"
                  >
                    <span class="font-weight-regular color-primary">{{ item.name.substring(0, 62) }}...</span>
                  </router-link>
                </div>
              </template>
              <span>{{ item.name }}</span>
            </v-tooltip>
          </div>
          <!-- 3 dots menu on the right -->
          <div>
            <v-menu class="custom-edit-menu" offset-y nudge-left="75" transition="slide-y-transition"> <!-- Adjust nudge-left if necessary -->
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  variant="text"
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <!-- Menu with actions -->
              <v-card class="pr-2 pl-1" style="border: #CBD5E1 1px solid;">
                <v-list dense>
                  <!-- Deactivate/Activate button -->
                  <v-list-item
                    :disabled="item.isSystem"
                    link
                    data-cy="credential-activation"
                    class="text--secondary font-weight-light"
                    @click="$emit('changeStatus', {id: item.id, status: item.status})"
                  >
                    {{ item.status === 'ACTIVE' ? $lang.actions.deactivate : $lang.actions.activate }}
                  </v-list-item>

                  <!-- Copy Create button -->
                  <v-list-item
                    v-if="roles.includes('CREDENTIAL_CREATOR')"
                    link
                    class="text--secondary font-weight-light"
                    @click="copyCreateResource(item, 'credentialCreate', $router, '', $options.filters.formatDateTime(new Date()), false, true)"
                  >
                    {{ $lang.actions.copyCreate }}
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </div>
      </template>

      <template v-slot:item.type="{ item }">
        <div>{{ $lang.status[item.type] }}</div>
      </template>

      <template v-slot:item.createdOn="{ item }">
        <div>{{ item.createdOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.modifiedOn="{ item }">
        <div>{{ item.modifiedOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.status="{ item }">
        <div class="custom-active-deactive">
          <p :class="{'custom-active': item.status === 'ACTIVE', 'custom-deactivated': item.status !== 'ACTIVE', 'm-0 p-0 mb-0': true }">
            {{ $lang.status[item.status] }}
          </p>
        </div>
      </template>

      <template v-slot:item.isSystem="{ item }">
        <div>{{ item.isSystem ? $lang.status.YES : $lang.status.NO }}</div>
      </template>
    </v-data-table>
    <v-dialog v-if="showTypeSelector" v-model="showTypeSelector" max-width="30%">
      <create-dialog-modal
        :types="credentialTypes"
        @create="createCredential"
        @closeDialog="showTypeSelector = false"
      ></create-dialog-modal>
    </v-dialog>
  </v-container>
</template>

<script>
import CreateDialogModal from '../../components/ui/modals/CreateDialogModal'
import { mapState } from 'vuex'
import { bus } from '@/main'
import { copyCreateResource } from '@/utils/helpers'
import { definitions } from '@/utils/definitions'
import { debounce } from 'lodash'

export default {
  components: {
    CreateDialogModal
  },
  props: {
    items: {
      type: Object,
      default: () => {
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      lock: true,
      showTypeSelector: false,
      activeOnly: true,
      search: '',
      options: {},
      totalItems: 0,
      headers: [
        {
          text: this.$lang.labels.name,
          align: 'start',
          sortable: true,
          value: 'name'
        },
        { text: this.$lang.labels.system, value: 'isSystem', sortable: true },
        { text: this.$lang.header.type, value: 'type' },
        { text: this.$lang.labels.status, value: 'status', sortable: true }
        // { text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false }
      ],
      credentialTypes: definitions.CreateCredentialFields.properties.type.enum.sort(),
      selectedType: '',
      roles: []
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userRoles', 'userSettings']),
    typesFormatted() {
      return this.credentialTypes.map((item) => {
        return { text: this.$lang.status[item], value: item }
      })
    }
  },
  watch: {
    options: {
      handler() {
        if (!this.lock) this.$emit('fetchCredentials', {
          options: this.options,
          search: this.search && this.search.length > 1 ? this.search : '',
          type: this.selectedType,
          resetPage: false
        })
        this.savePreFill()
      },
      deep: true
    },
    search: {
      handler: debounce( function (val) {
        if (val && val.length > 1 && !this.lock) this.$emit('fetchCredentials', {
          options: this.options,
          search: val,
          type: this.selectedType,
          resetPage: true
        })
        if (!this.lock && (val === null || val === '')) this.$emit('fetchCredentials', {
          options: this.options,
          search: '',
          type: this.selectedType,
          resetPage: true
        })
        this.options.page = 1
        this.savePreFill()
      }, 500)
    },
    activeOnly: {
      handler(val) {
        if (!this.lock) this.$emit('activeOnly', {
          activeOnly: val,
          options: {
            options: this.options,
            search: this.search && this.search.length > 1 ? this.search : '',
            type: this.selectedType,
            resetPage: true
          }
        })
        this.options.page = 1
        this.savePreFill()
      }
    },
    selectedType: {
      handler() {
        if (!this.lock) this.$emit('fetchCredentials', {
          options: this.options,
          search: this.search && this.search.length > 1 ? this.search : '',
          type: this.selectedType,
          resetPage: true
        })
        this.savePreFill()
        this.options.page = 1
      }
    }
  },
  mounted() {
    bus.$on('refreshData', (name) => {
      if (!this.lock && name === this.$route.name) this.$emit('fetchCredentials', {
        options: this.options,
        search: this.search && this.search.length > 1 ? this.search : '',
        type: this.selectedType,
        resetPage: true
      })
      this.options.page = 1
    })
  },
  created() {
    this.roles = this.userRoles.map((x) => x.name) || []

    if (this.userSettings.display.showModifiedOn) {
      this.headers.splice(4, 0, { text: this.$lang.labels.modifiedOn, value: 'modifiedOn' })
    }

    if (this.userSettings.display.showCreatedOn) {
      this.headers.splice(4, 0, { text: this.$lang.labels.createdOn, value: 'createdOn', class: 'created-on' })
    }

    if (this.userSettings.display.showId) {
      this.headers.splice(0, 0, { text: this.$lang.header.id, value: 'id' })
    }

    if (localStorage.preFillData) {
      const preFill = JSON.parse(localStorage.preFillData)

      if (preFill && preFill.credentialList) {
        this.search = preFill.credentialList.search
        this.activeOnly = preFill.credentialList.activeOnly
        this.selectedType = preFill.credentialList.selectedType
        this.options = preFill.credentialList.options

        this.$emit('activeOnly', {
          activeOnly: this.activeOnly,
          options: {
            options: this.options,
            search: this.search && this.search.length > 1 ? this.search : '',
            type: this.selectedType
          }
        })
      } else {
        this.$emit('fetchCredentials', {
          options: this.options,
          search: this.search && this.search.length > 1 ? this.search : '',
          type: this.selectedType,
          resetPage: true
        })
        this.options.page = 1
      }
    } else {
      this.$emit('fetchCredentials', {
        options: this.options,
        search: this.search && this.search.length > 1 ? this.search : '',
        type: this.selectedType,
        resetPage: true
      })
      this.options.page = 1
    }
    setTimeout(() => this.lock = false, 100)
  },
  methods: {
    copyCreateResource,
    savePreFill() {
      if (!localStorage.preFillData) localStorage.preFillData = '{}'
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (!preFill.credentialList) preFill.credentialList = {
          search: '',
          activeOnly: true,
          selectedType: '',
          options: {
            page: 1,
            itemsPerPage: 25,
            sortBy: [],
            sortDesc: []
          }
        }

        preFill.credentialList.search = this.search
        preFill.credentialList.activeOnly = this.activeOnly
        preFill.credentialList.selectedType = this.selectedType
        preFill.credentialList.options = { ...this.options, page: 1 }

        localStorage.preFillData = JSON.stringify(preFill)
      }
    },
    createCredential(type) {
      this.showTypeSelector = false
      this.$router.push({ name: 'credentialCreate', params: { type: type.toLowerCase() } })
    }
  }
}
</script>
