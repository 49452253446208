<template>
  <div style="width: 100%;" class="custom-container">
    <v-row dense no-gutters>
      <v-col cols="12">
        <Table
          :key="tableKey"
          :items="credentials"
          :loading="loading"
          @activeOnly="activeOnlyFunction($event)"
          @changeStatus="changeStatus($event)"
          @fetchCredentials="fetchCredentials($event)"
        ></Table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Table from './CredentialsDatatable'
import {
  getProcessCredentialsUsingGET as getCredentials,
  disableProcessCredentialUsingGET as disableCredential,
  enableProcessCredentialUsingGET as enableCredential
} from '@/utils/api'
import { mapActions } from 'vuex'

export default {
  components: {
    Table
  },
  data() {
    return {
      err: '',
      success: '',
      activeOnly: true,
      tableKey: 0,
      loading: false,
      credentials: { items: [], meta: {} }
    }
  },
  computed: {},
  created() {
    if (this.$route.params && this.$route.params.message) {
      this.addSnackbar({
        message: this.$route.params.message,
        timeout: 5000,
        color: 'success'
      })
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),

    activeOnlyFunction(event) {
      this.activeOnly = event.activeOnly

      this.fetchCredentials(event.options)
    },
    fetchCredentials(options) {
      this.loading = true

      const obj = {}

      if (options) {
        if (options.options && options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page || 1
          obj.size = options.options.itemsPerPage || 25
        } else {
          obj.page = 1
          obj.size = 25
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      if (options?.options?.sortDesc && options.options.sortDesc.length > 0) obj.orderDirection = options.options.sortDesc[0] ? 'DESC' : 'ASC'

      if (options && options.options && options.options.sortBy && options.options.sortBy.length > 0) {
        obj.orderField = options.options.sortBy[0]
      }

      if (options && options.search && options.search.length > 1) {
        obj.name = options.search
      }

      if (options && options.type) {
        obj.type = options.type
      }

      if (this.activeOnly) obj.status = 'ACTIVE'

      getCredentials(obj)
        .then((res) => {
          this.credentials = res.data.data
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    },
    async changeStatus (item) {
      try {
        const res = item.status === 'ACTIVE' ? await disableCredential({ id: item.id }) : await enableCredential({ id: item.id })

        if (res.status !== 200) {

          this.addSnackbar({
            message: this.$lang.errors.credentialStatusUpdate,
            timeout: 5000,
            color: 'error'
          })

          return
        }
        const successMessage = item.status !== 'ACTIVE' ? this.$lang.success.credentialActivated : this.$lang.success.credentialDeactivated

        this.addSnackbar({
          message: successMessage,
          timeout: 5000,
          color: 'success'
        })
        const changedItem = this.credentials.items.find((x) => x.id === item.id)

        if (changedItem) this.credentials.items.find((x) => x.id === item.id).status = res.data.data.status

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
      }
    }
  }
}
</script>
